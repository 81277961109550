




































import { Component, Prop } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import { FiltersConfig, QueryParams } from '@movecloser/front-core/lib/contracts/filter-parser'

import { FilterParamConfig, NavigationItem } from '../../../../../../contexts'
import { FiltersHandlerMixin } from '../../../../../../support/mixins/FiltersHandler.mixin.vue'

import { AccordionItem } from '../../../../../../dsl/atoms/Accordion'
import { FilterBadges } from '../../../../molecules/FilterBadges/FilterBadges.vue'
import { FilterList } from '../../../../molecules/FilterList'
import { FilterListProps } from '../../../../molecules/FilterList/FilterList.contracts'
import { Loader } from '../../../../molecules/Loader'

import { FilterAccordionLabel } from './FilterAccordionLabel.vue'
import { FilterPopover } from '../FilterPopover/FilterPopover.vue'
import { FiltersComponentConfig } from '../../Filters.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<FilterAccordion>({
  name: 'FilterAccordion',
  components: { FilterAccordionLabel, FilterBadges, FilterList, FilterPopover, Loader },
  created () {
    this.initComponentData()
    this.initShownCategoryNavItems()
    this.initAccordionItems()
  }
})
export class FilterAccordion extends FiltersHandlerMixin {
  @Prop({ required: false, type: Object })
  public configuration?: FiltersComponentConfig

  @Prop({ required: true, type: Object })
  public icons!: Record<string, string>

  @Prop({ type: Boolean, required: false, default: false })
  public filtersLoading!: boolean

  @Prop({ required: true, type: Array })
  public params!: FilterParamConfig[]

  @Prop({ required: true, type: Object })
  public query!: QueryParams

  @Prop({ required: false, type: Array })
  public navData?: NavigationItem[]

  @Prop({ required: false, type: Boolean })
  public readonly hideListControlParamsOnMobile?: boolean

  public accordionItems: AccordionItem[] = []

  public isExpanded: boolean = false

  public listControlsVisible: boolean = true

  public shownCategoryNav: NavigationItem[] = []

  public SHOWN_CATEGORY_NAV_ITEMS = 6

  /**
   * Config that stores initial filters data
   */
  public initialConfig!: AnyObject

  protected filters: FiltersConfig | null = this.initFilters(this.params, this.query)

  public initAccordionItems (): void {
    if (this.filters === null || typeof this.initialConfig.params === 'undefined') {
      return
    }

    this.accordionItems = this.initialConfig.params.map((item: FilterParamConfig) => {
      const filters = this.filters ? this.filters[item.queryParam] : null

      const props: FilterListProps = {
        isMulti: item.isMulti,
        items: item.options,
        filter: filters,
        queryParam: item.queryParam,
        sort: item.sort
      }

      return {
        id: item.queryParam,
        classname: item.isOpen ? 'Filters__param-list--always-open' : '',
        label: {
          component: FilterAccordionLabel,
          props: {
            label: item.label,
            count: this.getCount(item.queryParam)
          }
        },
        isOpen: this.shouldSelectedFiltersBeOpen && Array.isArray(filters) && filters.length > 0 ? true : item.isOpen,
        content: {
          component: FilterList,
          props,
          on: {
            'update:filter': (value: string) => {
              this.filters = { ...this.filters, [item.queryParam]: value }
              this.applyChanges()
            }
          }
        }
      }
    })
  }

  public get areControlsVisible (): boolean {
    return this.listControlsVisible
  }

  public get isListExpanded (): boolean {
    return this.isExpanded
  }

  public get shownCategoryNavItems (): NavigationItem[] {
    return this.shownCategoryNav
  }

  public get shouldApplyLoader (): boolean {
    return !!this.configuration && this.configuration.shouldApplyLoader
  }

  public get shouldSelectedFiltersBeOpen (): boolean {
    return !!this.configuration && this.configuration.shouldSelectedFiltersBeOpen
  }

  public applyChanges () {
    this.$emit('setQuery', this.getQueryParams(this.filters, this.query), this.filters)
  }

  /**
   * Expand list with category nav links
   */
  public expandCategoryNav (): void {
    this.isExpanded = true

    if (this.navData && this.navData.length > 0) {
      this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }

  /**
   * Close list with category nav links
   */
  public closeCategoryNav (): void {
    this.isExpanded = false

    if (this.navData && this.navData.length > 0) {
      if (this.navData.length < 6) {
        this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length
      } else {
        this.SHOWN_CATEGORY_NAV_ITEMS = 6
      }

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }

  public onApplyFilters (newFilters: FiltersConfig) {
    this.filters = this.updateAllFilters(this.filters, newFilters)
  }

  public onClearFilters () {
    this.filters = this.clearFilters(this.params, this.filters)
    this.applyChanges()
  }

  public onRemoveFilter (queryParam: string, value: string) {
    this.filters = this.removeFilter(this.filters, this.params, queryParam, value)
    this.applyChanges()
  }

  protected getCount (queryParam: string): number | null {
    if (!this.filters) {
      return null
    }

    const filter = this.filters[queryParam]

    if (!filter || !Array.isArray(filter)) {
      return null
    }

    return filter.length
  }

  /**
   * @protected
   */
  protected initComponentData (): void {
    this.initialConfig = {
      clearFilters: this.clearFilters,
      filters: this.filters ?? {},
      hideListControlParamsOnMobile: this.hideListControlParamsOnMobile,
      params: this.params,
      onApply: this.onApplyFilters,
      removeFilter: this.onRemoveFilter
    }
  }

  /**
   * Compose initially shown category nav links
   * @protected
   */
  protected initShownCategoryNavItems (): void {
    if (this.navData && this.navData.length > 0) {
      if (this.navData.length < 6) {
        this.SHOWN_CATEGORY_NAV_ITEMS = this.navData.length

        /**
         * Hide list controls visibility
         */
        this.listControlsVisible = false
      } else {
        this.SHOWN_CATEGORY_NAV_ITEMS = 6
      }

      this.shownCategoryNav = this.navData.slice(0, this.SHOWN_CATEGORY_NAV_ITEMS)
    }
  }
}

export default FilterAccordion
